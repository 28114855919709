import React from "react";
import { graphql } from "gatsby";
import { useIntl } from "gatsby-plugin-intl";
import { Container, Row, Col } from "react-bootstrap";

import PageWrapper from "../components/PageWrapper";
import { Section, Title } from "../components/Core";

import BlogList from "../sections/blog/BlogList";
import Seo from "../components/Seo/seo";

const Blog = ({ data }) => {
  const intl = useIntl();
  const currentLocale = intl.locale;
  const posts = data.allConfluencePage.edges
    .sort((a, b) => new Date(b.node.createdDate) - new Date(a.node.createdDate))
    .map((n) => ({
      node: n.node,
      // We need to filter datas by the article datas. So we add a locale filter.
      metadata: n.node.metadata.labels.results.filter((res) =>
        res.label.includes(currentLocale)
      ),
    }))
    // And we just render the metadatas that are mostly > 0
    .filter((n) => n.metadata.length > 0)
    .map((n) => n.node);

  return (
    <>
      <PageWrapper headerDark>
        <Seo
          title="Blog - Our Latest Articles"
          desc="Learn more about our tools, about the way to be a better poker player."
        />
        <Section bg="black" className="pb-0 position-relative">
          <div className="pt-5"></div>
          <Container>
            <Row className="justify-content-center text-center">
              <Col lg="8">
                <Title variant="hero" color="light">
                  {intl.formatMessage({
                    id: "blog.hero.title",
                  })}
                </Title>
              </Col>
            </Row>
          </Container>
        </Section>
        <BlogList locale={intl.locale} posts={posts} />
      </PageWrapper>
    </>
  );
};

export default Blog;

export const query = graphql`
  query {
    allConfluencePage {
      edges {
        node {
          title
          slug
          imageHeader
          summary
          createdDate
          metadata {
            labels {
              results {
                name
                label
              }
            }
          }
        }
      }
    }
  }
`;
