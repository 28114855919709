import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "gatsby-plugin-intl";
import moment from "moment";
import wptGlobalListViewPng from "../../assets/image/flop/blog/WPT_Global_list_view.png";

import { Section } from "../../components/Core";
import PostCard from "../../components/PostCard";

const BlogList = ({ locale, posts }) => {
  moment.locale(locale);

  return (
    <>
      {/* <!-- Blog section --> */}
      <Section className="position-relative pt-5 pb-5">
        <Container>
          <Row className="justify-content-center">
            <Col lg="4" className="mb-5">
              <Link to={'/blog/wptGlobal'}>
                <PostCard
                    img={wptGlobalListViewPng}
                    preTitle={moment('2022-05-28T08:28:38.511Z').format("LL")}
                    title={'FLOP Celebrates WPT Global Affiliation with $10k Freeroll'}
                    imgHeight={380}
                    link={'/blog/wptBlog'}
                    readMore
                />
              </Link>
            </Col>
            {posts.map(({ createdDate, imageHeader, slug, summary, title }) => (
              <Col lg="4" className="mb-5">
                <Link to={`/blog/${slug}`}>
                  <PostCard
                    img={imageHeader}
                    imgHeight={100}
                    link={`/blog/${slug}`}
                    preTitle={moment(createdDate).format("LL")}
                    title={title}
                    readMore
                  >
                    {summary}
                  </PostCard>
                </Link>
              </Col>
            ))}
          </Row>
          {/* <Box className="d-flex justify-content-center" mt={4}>
          <Pagination>
            <PageItem>
              <FaAngleLeft />
            </PageItem>
            <PageItem>1</PageItem>
            <PageItem>2</PageItem>
            <PageItem>3</PageItem>
            <PageItem>...</PageItem>
            <PageItem>9</PageItem>
            <PageItem>
              <FaAngleRight />
            </PageItem>
          </Pagination>
        </Box> */}
        </Container>
      </Section>
    </>
  );
};

export default BlogList;
